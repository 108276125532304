export default {
  metaInfo: {
    title: 'Privacy verklaring',
  },
  name: 'Privacy',
  components: {
  },
  props: {},
  data: () => ({

  }),
  computed: {},
  methods: {},
};
